const sliderWrappers = [...document.querySelectorAll('.js-slider-wrapper')];

for (const slider of sliderWrappers) {
    const GUTTER_MOBILE = '2.4%';
    const GUTTER_DESKTOP = '19%';
    const swiper = new Swiper(slider.querySelector('.swiper-container'), {
        observer: true,
        observerParents: true,
        spaceBetween: GUTTER_MOBILE,
        speed: 500,
        navigation: {
            prevEl: slider.querySelector('.js-slider-arrow-prev'),
            nextEl: slider.querySelector('.js-slider-arrow-next'),
        },
        breakpoints: {
            1024: {
                slidesPerView: 2,
                spaceBetween: GUTTER_DESKTOP,
            },
        },
        scrollbar: {
            el: slider.querySelector('.swiper-scrollbar'),
            hide: false,
        },
    });
}

const modal = document.querySelector('.modal'),
    modalVideo = modal.querySelector('.modal__video'),
    modalBg = modal.querySelector('.modal__bg'),
    modalCloseButton = modal.querySelector('.modal__close'),
    modalTrigger = document.querySelector('.video__asset');

const openModal = () => {
    modal.classList.add('is-open');
    modalVideo.play();
};

const closeModal = () => {
    modal.classList.remove('is-open');
    modalVideo.pause();
    modalVideo.currentTime = 0;
};

modalTrigger.addEventListener('click', openModal);

modalBg.addEventListener('click', closeModal);
modalCloseButton.addEventListener('click', closeModal);
